<template>
    <v-main fluid>
        <v-container class="p-0 px-2">
            <v-row class="d-flex justify-content-center">
                <v-col cols="12" sm="10">
                    <div class="bg-white border p-5">
                        <h1 class="display-3">{{$t("Applicationform")}}</h1>
                        <p class="caption">{{$t("NewSuppliersCanRegisterOnThisPage")}}</p>
                        <hr />
                        <p class="headline">{{$t("GeneralInformation")}}</p>
                        <v-row>
                            <v-col cols="12">
                                {{$t("CompanyName")}}
                                <v-text-field id="companyname"
                                              ref="companyname"
                                              v-model="model.companyname"
                                              v-bind:error="errors.companyname"
                                              v-bind:disabled="disabled.companyname"
                                              dense
                                              outlined
                                              v-bind:messages="$t('Info') + ': ' + $t('DoNotUseAbbreviationsOnCompanyName')"
                                              color="primary"
                                              v-bind:placeholder="$t('CompanyName')"
                                              v-bind:background-color="disabled.companyname ? 'grey lighten-3' : 'white'"
                                              v-on:blur="handleBlurCompanyName"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                {{$t("TurkeyBasedAbroad")}}
                                <v-select id="turkeybasedabroad"
                                          ref="turkeybasedabroad"
                                          v-model="model.turkeybasedabroad"
                                          v-bind:items="items.turkeybasedabroad"
                                          item-text="text"
                                          item-value="value"
                                          v-bind:error="errors.turkeybasedabroad"
                                          v-bind:disabled="disabled.turkeybasedabroad"
                                          dense
                                          outlined
                                          hide-details
                                          color="primary"
                                          v-bind:placeholder="$t('TurkeyBasedAbroad')"
                                          v-on:change="Changed_TurkeyBasedAbroad()"
                                          :background-color="disabled.turkeybasedabroad ? 'grey lighten-3' : 'white'">
                                    <template v-slot:selection="data">
                                        {{$t(data.item.text)}}
                                    </template>
                                    <template v-slot:item="data">
                                        <v-list-item>
                                            {{$t(data.item.text)}}
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="12" md="3">
                                {{$t("IsSoleTrader")}}
                                <v-select id="issoletrader"
                                          ref="issoletrader"
                                          v-model="model.issoletrader"
                                          v-bind:items="items.issoletrader"
                                          item-text="text"
                                          item-value="value"
                                          v-bind:error="errors.issoletrader"
                                          v-bind:disabled="disabled.issoletrader"
                                          dense
                                          outlined
                                          hide-details
                                          color="primary"
                                          v-bind:placeholder="$t('IsSoleTrader')"
                                          v-on:change="Changed_IsSoleTrader()"
                                          :background-color="disabled.issoletrader ? 'grey lighten-3' : 'white'">
                                    <template v-slot:selection="data">
                                        {{$t(data.item.text)}}
                                    </template>
                                    <template v-slot:item="data">
                                        <v-list-item>
                                            {{$t(data.item.text)}}
                                        </v-list-item>
                                        <v-divider class="mt-2"></v-divider>
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="12" md="6">
                                {{$t(customtext.vknortckn)}}
                                <v-text-field id="vknortckn"
                                              ref="vknortckn"
                                              v-model="model.vknortckn"
                                              v-bind:error="errors.vknortckn"
                                              v-bind:disabled="disabled.vknortckn"
                                              dense
                                              outlined
                                              hide-details
                                              color="primary"
                                              v-bind:placeholder="$t(customtext.vknortckn)"
                                              v-mask="masktvknortckn"
                                              :background-color="disabled.vknortckn ? 'grey lighten-3' : 'white'">
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                {{$t("sector")}}
                                <v-autocomplete id="sector"
                                                ref="sector"
                                                v-model="model.sector"
                                                v-bind:items="items.sector"
                                                item-text="text"
                                                item-value="value"
                                                v-bind:error="errors.sector"
                                                v-bind:disabled="disabled.sector"
                                                dense
                                                outlined
                                                :messages="$t('Warning') + ': ' + $t('SectorChangesDeleteSubsectorsSelection')"
                                                multiple
                                                prepend-inner-icon="mdi-database-search"
                                                append-icon=""
                                                color="primary"
                                                v-bind:placeholder="$t('sector')"
                                                v-on:change="Changed_Sector()"
                                                :filter="Filter_SectorAndSubSector"
                                                :background-color="disabled.sector ? 'grey lighten-3' : 'white'">
                                    <template v-slot:selection="{ active, item, attrs, on }">
                                        <v-chip label small close close-icon="mdi-close" color="primary white--text" class="my-1" v-on:click:close="Remove_Sector(item)">{{$t(item.text)}}</v-chip>
                                    </template>
                                    <template v-slot:item="{ active, item, attrs, on }">
                                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                            <v-list-item-action>
                                                <v-checkbox :input-value="active"></v-checkbox>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <v-row no-gutters>
                                                        {{$t(item.text)}}
                                                    </v-row>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="6">
                                {{$t("subsector")}}
                                <v-autocomplete id="subsector"
                                                ref="subsector"
                                                v-model="model.subsector"
                                                v-bind:items="items.subsector"
                                                item-text="text"
                                                item-value="value"
                                                v-bind:error="errors.subsector"
                                                v-bind:disabled="disabled.subsector"
                                                dense
                                                outlined
                                                hide-details
                                                multiple
                                                prepend-inner-icon="mdi-database-search"
                                                append-icon=""
                                                color="primary"
                                                v-bind:placeholder="$t('subsector')"
                                                :filter="Filter_SectorAndSubSector"
                                                :background-color="disabled.subsector ? 'grey lighten-3' : 'white'">
                                    <template v-slot:selection="{ active, item, attrs, on }">
                                        <v-chip label small close close-icon="mdi-close" color="primary white--text" class="my-1" v-on:click:close="Remove_SubSector(item)">{{$t(item.text)}}</v-chip>
                                    </template>
                                    <template v-slot:item="{ active, item, attrs, on }">
                                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                            <v-list-item-action>
                                                <v-checkbox :input-value="active"></v-checkbox>
                                            </v-list-item-action>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <v-row no-gutters>
                                                        {{$t(item.text)}}
                                                    </v-row>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>

                        <hr />

                        <p class="headline">{{$t("ContactInformation")}}</p>
                        <v-row>
                            <v-col cols="12" md="4">
                                {{$t("EmailAdress")}}
                                <v-text-field id="email"
                                              ref="email"
                                              v-model="model.email"
                                              v-bind:error="errors.email"
                                              v-bind:disabled="disabled.email"
                                              dense
                                              outlined
                                              hide-details
                                              color="primary"
                                              v-bind:placeholder="$t('EmailAdress')"
                                              :background-color="disabled.email ? 'grey lighten-3' : 'white'"
                                              v-on:blur="handleBlurEmail"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                {{$t("CompanyExecutiveName")}}
                                <v-text-field id="executivename"
                                              ref="executivename"
                                              v-model="model.executivename"
                                              v-bind:error="errors.executivename"
                                              v-bind:disabled="disabled.executivename"
                                              dense
                                              outlined
                                              hide-details
                                              color="primary"
                                              v-bind:placeholder="$t('CompanyExecutiveName')"
                                              :background-color="disabled.executivename ? 'grey lighten-3' : 'white'"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                {{$t("CompanyExecutiveSurname")}}
                                <v-text-field id="executivesurname"
                                              ref="executivesurname"
                                              v-model="model.executivesurname"
                                              v-bind:error="errors.executivesurname"
                                              v-bind:disabled="disabled.executive" name
                                              dense
                                              outlined
                                              hide-details
                                              color="primary"
                                              v-bind:placeholder="$t('CompanyExecutiveSurname')"
                                              :background-color="disabled.executivesurname ? 'grey lighten-3' : 'white'"></v-text-field>
                            </v-col>
                            <v-col cols="4" md="3">
                                {{$t("Phonecode")}}
                                <v-autocomplete id="phonecode"
                                                ref="phonecode"
                                                v-model="model.phonecode"
                                                v-bind:items="items.phonecode"
                                                item-text="text"
                                                item-value="value"
                                                v-bind:error="errors.phonecode"
                                                v-bind:disabled="disabled.phonecode"
                                                dense
                                                outlined
                                                hide-details
                                                prepend-inner-icon="mdi-database-search"
                                                append-icon=""
                                                color="primary"
                                                v-on:change="Changed_Phonecode()"
                                                v-bind:placeholder="$t('Phonecode')"
                                                :filter="Filter_Phonecode"
                                                :background-color="disabled.phonecode ? 'grey lighten-3' : 'white'">
                                    <template v-slot:selection="{ active, item, attrs, on }">
                                        {{$t(item.text)}}
                                    </template>
                                    <template v-slot:item="{ active, item, attrs, on }">
                                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <v-row no-gutters>
                                                        {{item.ulke_en}} | {{item.text}}
                                                    </v-row>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="8" md="9">
                                {{$t("Gsm")}}
                                <v-text-field id="gsm"
                                              ref="gsm"
                                              v-model="model.gsm"
                                              v-bind:error="errors.gsm"
                                              v-bind:disabled="disabled.gsm"
                                              dense
                                              outlined
                                              hide-details
                                              color="primary"
                                              v-bind:placeholder="$t('Gsm')"
                                              v-mask="maskgsm"
                                              :background-color="disabled.gsm ? 'grey lighten-3' : 'white'"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                {{$t("Country")}}
                                <v-autocomplete id="country"
                                                ref="country"
                                                v-model="model.country"
                                                v-bind:items="items.country"
                                                item-text="text"
                                                return-object
                                                v-bind:error="errors.country"
                                                v-bind:disabled="disabled.country"
                                                dense
                                                outlined
                                                hide-details
                                                prepend-inner-icon="mdi-database-search"
                                                append-icon=""
                                                color="primary"
                                                @change="Changed_Country()"
                                                v-bind:placeholder="$t('Country')"
                                                :filter="Filter_Country"
                                                :background-color="disabled.country ? 'grey lighten-3' : 'white'">
                                    <template v-slot:selection="{ active, item, attrs, on }">
                                        {{$t(item.text)}}
                                    </template>
                                    <template v-slot:item="{ active, item, attrs, on }">
                                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <v-row no-gutters>
                                                        {{item.text}}
                                                    </v-row>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="4">
                                {{$t("City")}}
                                <v-autocomplete id="province"
                                                ref="province"
                                                v-model="model.province"
                                                v-bind:items="items.province"
                                                item-text="text"
                                                item-value="value"
                                                v-bind:error="errors.province"
                                                v-bind:disabled="disabled.province"
                                                dense
                                                outlined
                                                hide-details
                                                prepend-inner-icon="mdi-database-search"
                                                append-icon=""
                                                color="primary"
                                                v-on:change="Changed_Province()"
                                                v-bind:placeholder="$t('City')"
                                                :filter="Filter_Province"
                                                :background-color="disabled.province ? 'grey lighten-3' : 'white'">
                                    <template v-slot:selection="{ active, item, attrs, on }">
                                        {{$t(item.text)}}
                                    </template>
                                    <template v-slot:item="{ active, item, attrs, on }">
                                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <v-row no-gutters>
                                                        {{item.text}}
                                                    </v-row>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="4">
                                {{$t("County")}}
                                <v-autocomplete id="district"
                                                ref="district"
                                                v-model="model.district"
                                                v-bind:items="items.district"
                                                item-text="text"
                                                item-value="value"
                                                v-bind:error="errors.district"
                                                v-bind:disabled="disabled.district"
                                                dense
                                                outlined
                                                hide-details
                                                prepend-inner-icon="mdi-database-search"
                                                append-icon=""
                                                color="primary"
                                                v-bind:placeholder="$t('County')"
                                                :filter="Filter_District"
                                                :background-color="disabled.district ? 'grey lighten-3' : 'white'">
                                    <template v-slot:selection="{ active, item, attrs, on }">
                                        {{$t(item.text)}}
                                    </template>
                                    <template v-slot:item="{ active, item, attrs, on }">
                                        <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <v-row no-gutters>
                                                        {{item.text}}
                                                    </v-row>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12">
                                {{$t("Address")}}
                                <v-textarea id="address"
                                            ref="address"
                                            v-model="model.address"
                                            v-bind:error="errors.address"
                                            v-bind:disabled="disabled.address"
                                            dense
                                            outlined
                                            hide-details
                                            color="primary"
                                            v-bind:placeholder="$t('Address')"
                                            :background-color="disabled.address ? 'grey lighten-3' : 'white'"></v-textarea>
                            </v-col>
                        </v-row>

                        <v-row class="mt-6 d-flex justify-content-center align-items-center">
                            <v-col cols="auto" class="mr-0 pr-0">
                                <v-skeleton-loader v-if="captchaUrl.length == 0"
                                                   max-width="240"
                                                   max-height="70"
                                                   min-width="240"
                                                   min-height="70"
                                                   type="image"></v-skeleton-loader>
                                <img v-else
                                     alt="Captcha"
                                     v-bind:src="captchaUrl"
                                     width="240"
                                     height="70"
                                     v-bind:style="{ 'filter' : 'blur(' + (captchaBlur ? '5px' : '0px') + ')'}" />
                            </v-col>
                            <v-col cols="auto">
                                <v-btn depressed icon v-on:click="ReloadCaptcha()" color="primary">
                                    <v-icon>{{ captchaBlur ? 'mdi-eye' : 'cached'}}</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>

                        {{$t("SecurityCode")}}
                        <v-text-field v-model="model.captcha" v-bind:error="errors.captcha" dense outlined hide-details color="primary" append-icon="mdi-shield-check" v-bind:placeholder="$t('SecurityCode')"></v-text-field>

                        <v-row>
                            <v-col cols="12" class="mt-5">
                                <table>
                                    <tr v-for="(document, i) in documents" v-bind:key="i" class="my-0 py-0 d-flex align-center ">
                                        <td class="d-flex align-center my-0 py-0">
                                            <v-checkbox v-model="document.selected" v-bind:error="document.error" color="primary" class="my-0 py-0">
                                                <template v-slot:prepend>
                                                    <v-btn color="primary" icon v-on:click="PrepDocument(document.title)">
                                                        <v-icon>mdi-file-document-outline</v-icon>
                                                    </v-btn>
                                                </template>
                                                <template v-slot:label>
                                                    <span class="body-2" style="padding-top: 10px">{{$t(document.title + 'Description')}}</span>
                                                </template>
                                            </v-checkbox>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p class="caption" v-html="$t('ApplicationBottomDescription')"></p>
                                        </td>
                                    </tr>
                                </table>
                            </v-col>
                        </v-row>

                        <v-alert v-model="alert" type="error">
                            <span v-for="(Err, i) in ErrorText" :key="i">
                                {{$t(Err)}}<br />
                            </span>
                        </v-alert>

                        <v-btn v-bind:disabled="processing" depressed block tile color="primary white--text" v-on:click="Application()">{{$t("MakeAnApplication")}}</v-btn>
                    </div>
                    <v-progress-linear v-if="processing" color="primary" indeterminate></v-progress-linear>
                </v-col>
                <v-col v-if="false" cols="12" class="d-flex justify-content-center mb-6">
                    <p class="mb-0 mt-6 pb-0" v-html="$t('ForYourQuestionsAndSuggestions')"></p>
                </v-col>
            </v-row>
        </v-container>

        <v-dialog v-model="isAnyError" width="390">
            <v-card class="mx-auto pt-7 pb-2">
                <alertIcon icon="error"></alertIcon>
                <v-card-title class="mb-0 text-center">
                    <p class="mx-auto headline pb-0 mb-0">{{$t("Warning")}}</p>
                </v-card-title>
                <v-card-text>
                    <span v-for="(Err, i) in ErrorText" :key="i">
                        {{$t(Err)}}<br />
                    </span>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text color="red" class="white--text font-weight-bolder px-5" v-on:click="isAnyError = false">
                        {{$t("Ok")}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="processing" persistent>
            <v-card color="transparent" dark class="py-5">
                <v-card-text class="text-center py-5">
                    <v-progress-circular indeterminate
                                         :size="50"
                                         color="white"></v-progress-circular>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="Checkbox.Model" width="800" scrollable>
            <v-card class="pa-5">
                <v-card-title>
                    <span class="text-h5">{{$t(Checkbox.DocumentTitle)}}</span>
                    <v-spacer></v-spacer>
                    <v-btn icon v-on:click="Checkbox.Model = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <br />
                <v-card-text style="height: 600px" v-html="$t(Checkbox.DocumentContent)">

                </v-card-text>
                <v-card-actions>
                    <v-btn text block tile v-on:click="DownloadDocument()" color="primary" class="text-capitalize px-5">
                        <span class="caption">{{$t('Download')}}</span><v-icon small right>mdi-download</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-main>
</template>

<style>
    .v-dialog {
        box-shadow: none !important;
    }
</style>

<script>
    import axios from 'axios';
    import { FormMixIn } from '@/MixIns/FormMixins';
    import Utilities from '@/Utilities.js'

    export default {
        name: "Application",
        mixins: [FormMixIn],
        data: function ()
        {
            return {
                processing: false,
                alert: false,
                messages: { messageType: '', messageBody: '', infoBody: '' },
                imgURL: '',

                customtext: {
                    vknortckn: 'VKN'
                },

                documents: [
                    { selected: false, error: false, title: 'KVKKClarification' }
                ],

                captchaBlur: true,
                captchaUrl: '',

                model: {
                    companyname: '',
                    turkeybasedabroad: '',
                    issoletrader: '',
                    vknortckn: '',
                    sector: '',
                    subsector: '',
                    email: '',
                    executivename: '',
                    executivesurname: '',
                    phonecode: '90',
                    gsm: '',
                    country: {},
                    province: '',
                    district: '',
                    address: '',
                    captcha: ''
                },

                items: {
                    turkeybasedabroad: this.GetOptions_TurkeyBasedOrAbroad(),
                    issoletrader: this.GetOptions_IsSoleTrader(),
                    phonecode: this.GetOptions_Phonecode(),
                    country: this.GetOptions_Country(),
                    province: [],
                    district: [],
                    sector: this.GetOptions_Sector(),
                    subsector: []
                },

                disabled: {
                    companyname: false,
                    turkeybasedabroad: false,
                    issoletrader: false,
                    vknortckn: false,
                    sector: false,
                    subsector: false,
                    email: false,
                    executivename: false,
                    executivesurname: false,
                    phonecode: false,
                    gsm: false,
                    country: false,
                    province: false,
                    district: false,
                    address: false,
                    captcha: false
                },

                errors: {
                    companyname: false,
                    turkeybasedabroad: false,
                    issoletrader: false,
                    vknortckn: false,
                    sector: false,
                    subsector: false,
                    email: false,
                    executivename: false,
                    executivesurname: false,
                    phonecode: false,
                    gsm: false,
                    country: false,
                    province: false,
                    district: false,
                    address: false,
                    captcha: false
                },

                ErrorText: [],
                isAnyError: false,
                customErrorText: [],

                pageInputs: {
                    'Application': ['companyname', 'turkeybasedabroad', 'issoletrader', 'vknortckn', 'sector', 'subsector', 'email', 'executivename', 'executivesurname', 'phonecode', 'gsm', 'country', 'province', 'district', 'address', 'captcha'],
                },
                captchaUrl: '',

                Checkbox: {
                    Model: false,
                    DocumentTitle: '',
                    DocumentContent: '',
                    DocumentLink: ''
                }
            };
        },
        computed: {
            maskgsm: function ()
            {
                if (this.model.phonecode == '90')
                {
                    return '(###) ### ## ##';
                }
                else
                {
                    return '####################';
                }
            },

            masktvknortckn: function ()
            {
                if (this.model.issoletrader == '1')
                {
                    return '###########';
                }
                else
                {
                    return '##########';
                }
            },
        },
        watch: {

        },
        methods: {
            XYZ(title, desc)
            {
                let translateddesc = this.$root.$i18n.t(desc);
                let translatedtitle = this.$root.$i18n.t(title);
                return translateddesc.replace('{PrepLink}', translatedtitle);
            },
            PrepDocument(title)
            {
                this.Checkbox.Model = true;
                this.Checkbox.DocumentTitle = title;
                this.Checkbox.DocumentContent = title + 'Content';
                this.Checkbox.DocumentLink = title + 'Link';
            },
            OpenPrivacyPolicy()
            {
                this.Checkbox.Model = true;
                this.Checkbox.DocumentTitle = 'PrivacyPolicy';
                this.Checkbox.DocumentContent = 'PrivacyPolicyContent';
                this.Checkbox.DocumentLink = 'PrivacyPolicyLink'
            },
            OpenKVKKClarification()
            {
                this.Checkbox.Model = true;
                this.Checkbox.DocumentTitle = 'KVKKClarification';
                this.Checkbox.DocumentContent = 'KVKKClarificationContent';
                this.Checkbox.DocumentLink = 'KVKKClarificationLink'
            },
            DownloadDocument()
            {
                window.open(this.GetHostUrl + 'assets/docs/' + this.$root.$i18n.t(this.Checkbox.DocumentLink), 'blank')
            },
            Application()
            {
                var self = this;
                if (this.ValidateBeforeAction())
                {
                    this.processing = true;
                    var payload = {
                        action: 'WantToBeSupplier',
                        formdata: {
                            formInputs: {
                                companyname: this.model.companyname,
                                turkeybasedabroad: this.model.turkeybasedabroad,
                                issoletrader: this.model.issoletrader,
                                vknortckn: this.model.vknortckn,
                                sector: (this.model.sector || []).join(','),
                                subsector: (this.model.subsector || []).join(','),
                                email: this.model.email,
                                executivename: this.model.executivename,
                                executivesurname: this.model.executivesurname,
                                gsm: (this.model.gsm.match(/\d+/g) || []).join(''),
                                address: this.model.address,
                                phonecode: this.model.phonecode,
                                country: this.model.country.value,
                                province: this.model.province,
                                district: this.model.district
                            },
                            extData: []
                        },
                        controller: 'Login',
                        method: 'WantToBeSupplier',
                        captcha: this.model.captcha
                    };

                    this.$store.dispatch("AccountAction", payload)
                        .then(response =>
                        {
                            let messages = {};

                            if (response.data.errorCode == "0")
                            {
                                this.ClearErrors();
                                this.ClearModel();
                                this.InitializeForm();
                                this.ReloadCaptcha();

                                messages = {
                                    messageBody: self.$t(response.data.errorMessage),
                                    infoBody: self.$t('SUCCESS'),
                                    messageType: 'B',
                                    OnCloseDialog: function ()
                                    {
                                        self.$router.push("/Account/Login");
                                    }
                                };
                            }
                            else
                            {
                                this.alert = true;

                                this.isAnyError = true;
                                this.ReloadCaptcha();
                                this.Captcha = '';

                                messages = {
                                    messageBody: self.$t(response.data.errorMessage),
                                    infoBody: self.$t('Warning'),
                                    messageType: 'E'
                                };
                            }

                            self.$root.$emit('setUpMessage', messages);
                            this.processing = false;
                        })
                }
            },
            ValidateBeforeAction: function ()
            {
                this.ClearErrors();

                for (var ctrl in this.errors)
                {
                    if (!this.disabled[ctrl])
                    {
                        var data = this.model[ctrl];

                        if (ctrl == 'country')
                        {
                            data = this.model[ctrl].value;
                        }

                        if (data === undefined) data = '';

                        if (ctrl == 'email')
                        {
                            this.errors[ctrl] = (data == '');

                            if (!this.errors[ctrl])
                            {
                                var isEmailValidated = this.ValidateEmail(this.model.email);

                                if (!isEmailValidated)
                                    this.customErrorText.push('WarnEmailMustBeInRightFormat');

                                this.errors[ctrl] = (!isEmailValidated);
                            }

                        }
                        else if (ctrl == 'vknortckn')
                        {
                            this.errors[ctrl] = (data == '');

                            if (!this.errors[ctrl])
                            {
                                if (this.model.issoletrader == '1')
                                {
                                    this.errors[ctrl] = data.length != 11;

                                    if (this.errors[ctrl])
                                        this.customErrorText.push('WarnTCKNMustBe11Digit');
                                }
                                else
                                {
                                    this.errors[ctrl] = data.length != 10;

                                    if (this.errors[ctrl])
                                        this.customErrorText.push('WarnVKNMustBe10Digit');
                                }
                            }
                        }
                        else if (ctrl == 'gsm')
                        {
                            data = data.match(/\d+/g);

                            if (data == null)
                            {
                                data = '';
                            }
                            else
                            {
                                data = (data || []).join('');
                            }

                            this.errors[ctrl] = (data == '');

                            if (!this.errors[ctrl])
                            {
                                if (!this.ValidatePhoneNumber(data))
                                    this.customErrorText.push('WarnGSMMustBeInRightFormat');

                                if (this.model.phonecode == '90')
                                {
                                    if (data.startsWith('90'))
                                    {
                                        data = data.substring(2);
                                    }

                                    if (data.startsWith('0'))
                                    {
                                        data = data.substring(1);
                                    }

                                    this.errors[ctrl] = data.length != 10;

                                    if (this.errors[ctrl])
                                        this.customErrorText.push('WarnGSMNumber10Digits');
                                }
                                else
                                {
                                    this.errors[ctrl] = data.length < 1;

                                    if (this.errors[ctrl])
                                        this.customErrorText.push('WarnGSMNumberXDigitsMin');
                                }
                            }
                        }
                        else
                        {
                            this.errors[ctrl] = (data == '');
                        }
                    }
                }

                Utilities.log(this.customErrorText);

                for (var document of this.documents)
                {
                    if (!document.selected)
                    {
                        document.error = true;
                        this.customErrorText.push(document.title + 'ErrWarn');
                    }
                }

                this.PrepareErrorMessage(this.pageInputs['Application']);

                return !this.isAnyError;
            },
            ValidateCompanyName: function (value, turkeybasedabroad)
            {
                if (turkeybasedabroad == '30')
                {
                    return value.toLocaleUpperCase() == Utilities.normalizeTurkishChars(value.toLocaleUpperCase());
                }

                return true;
            },
            ValidateEmail: function (value)
            {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(value).toLowerCase());
            },
            ValidatePhoneNumber: function (value)
            {
                return /^\d+$/.test(value);
            },
            ClearErrors: function ()
            {
                this.alert = false;
                this.isAnyError = false;

                this.customErrorText = [];
                this.ErrorText = [];

                for (var document of this.documents)
                {
                    document.error = false;
                }

                for (var reset in this.errors)
                    this.errors[reset] = false;
            },
            PrepareErrorMessage: function (pageInputs)
            {
                var requiredErr = false;
                this.ErrorText = [];

                for (var err in this.errors)
                {
                    if (pageInputs.indexOf(err) > -1)
                    {
                        if (this.errors[err] == true)
                        {
                            if (this.model[err] == '') requiredErr = true;
                            this.isAnyError = true;
                        }
                    }
                }

                if (!this.isAnyError)
                {
                    for (var document of this.documents)
                    {
                        if (document.error)
                        {
                            this.isAnyError = true;
                            break;
                        }
                    }
                }

                if (this.isAnyError)
                {
                    if (requiredErr) this.ErrorText.push('WarnYouMustFillAllRequiredFields');

                    if (this.customErrorText.length > 0)
                        this.ErrorText.push(...this.customErrorText);
                }

                this.customErrorText = [];
            },
            ClearModel()
            {
                for (const [key, value] of Object.entries(this.model))
                {
                    this.model[key] = '';
                }

                for (var document of this.documents)
                {
                    document.selected = false;
                }
            },
            ReloadCaptcha: function (mounted)
            {
                const self = this;

                self.model.captcha = '';
                self.captchaUrl = '';

                const rnd = Math.floor(new Date().getTime() / 1000).toString();

                axios
                    .get(`/api/Account/Captcha?rnd=${rnd}`, {
                        responseType: 'arraybuffer',
                    })
                    .then((response) =>
                    {
                        const blob = new Blob([response.data], { type: response.headers['content-type'] });
                        const imageUrl = URL.createObjectURL(blob);
                        self.captchaUrl = imageUrl;

                        if (!mounted)
                        {
                            self.captchaBlur = false;
                        }
                    })
                    .catch((error) =>
                    {
                        self.errorMessages.push('TOO_MANY_REQUEST_TRY_AGAIN_LATER');
                    });
            },
            InitializeForm()
            {
                this.disabled.issoletrader = true;
                this.disabled.vknortckn = true;
                this.disabled.subsector = true;
                this.disabled.province = true;
                this.disabled.district = true;

                //this.model.turkeybasedabroad = '42';
                //this.disabled.turkeybasedabroad = true;

                //this.Changed_TurkeyBasedAbroad();
            },
            GetOptions_TurkeyBasedOrAbroad()
            {
                return [
                    { text: 'PleaseSelect', value: '' },
                    { text: 'TurkeyBased', value: '42' },
                    { text: 'Abroad', value: '30' }
                ];
            },
            GetOptions_IsSoleTrader()
            {
                return [
                    { text: 'PleaseSelect', value: '' },
                    { text: 'Yes', value: '1' },
                    { text: 'No', value: '0' }
                ];
            },
            GetOptions_Country()
            {
                let items = [];

                var req = {
                    payload: {
                        search: "",
                        tableName: "formdata",
                        parameters: {},
                        column: {
                            itemText: 'text'
                        }
                    },
                    queryName: "tedarik_ulke",
                    notAuthorizedRequest: true
                };
                req.success = function (result)
                {
                    result.data.listResult.forEach(function (obj)
                    {
                        items.push({ text: obj.ulke, value: obj.ulke.toString(), iso: obj.iso3, ulke_tr: obj.ulke_tr });
                    });
                };
                req.error = function (err)
                {
                    console.log(err);
                };

                this.$store.dispatch("GetLOV", req);

                return items;
            },
            GetOptions_Province()
            {
                let items = [];

                var req = {
                    payload: {
                        search: "",
                        tableName: "formdata",
                        parameters: {
                            ulke: (this.model.country.value || '').toString()
                        },
                        column: {
                            itemText: 'text'
                        }
                    },
                    queryName: "tedarik_il",
                    notAuthorizedRequest: true
                };
                req.success = function (result)
                {
                    console.log(result);
                    result.data.listResult.forEach(function (obj)
                    {
                        items.push({ text: obj.il, value: obj.il.toString() });
                    });
                };
                req.error = function (err)
                {
                    console.log(err);
                };

                this.$store.dispatch("GetLOV", req);

                this.items.province = items;
            },
            GetOptions_District()
            {
                let items = [];

                var req = {
                    payload: {
                        search: "",
                        tableName: "formdata",
                        parameters: {
                            ulke: (this.model.country.value || '').toString(),
                            il: (this.model.province || '').toString()
                        },
                        column: {
                            itemText: 'text'
                        }
                    },
                    queryName: "tedarik_ilce",
                    notAuthorizedRequest: true
                };
                req.success = function (result)
                {
                    result.data.listResult.forEach(function (obj)
                    {
                        items.push({ text: obj.ilce, value: obj.ilce.toString() });
                    });
                };
                req.error = function (err)
                {
                    console.log(err);
                };

                this.$store.dispatch("GetLOV", req);

                this.items.district = items;
            },
            GetOptions_Phonecode()
            {
                let items = [];

                var req = {
                    payload: {
                        search: "",
                        tableName: "formdata",
                        parameters: {},
                        column: {
                            itemText: 'text'
                        }
                    },
                    queryName: "tedarik_phonecode",
                    notAuthorizedRequest: true
                };
                req.success = function (result)
                {
                    result.data.listResult.forEach(function (obj)
                    {
                        items.push({ text: obj.text, value: obj.value.toString(), ulke_en: obj.en_ulke, ulke_tr: obj.tr_ulke });
                    });
                };
                req.error = function (err)
                {
                    console.log(err);
                };

                this.$store.dispatch("GetLOV", req);

                return items;
            },
            GetOptions_Sector()
            {
                let items = [];

                var req = {
                    payload: {
                        search: "",
                        tableName: "formdata",
                        parameters: {},
                        column: {
                            itemText: 'text'
                        }
                    },
                    queryName: "tedarik_sektor_key",
                    notAuthorizedRequest: true
                };
                req.success = function (result)
                {
                    result.data.listResult.forEach(function (obj)
                    {
                        items.push({ text: obj.text, value: obj.value.toString(), text_tr: obj.text_tr, text_en: obj.text_en });
                    });
                };
                req.error = function (err)
                {
                    console.log(err);
                };

                this.$store.dispatch("GetLOV", req);

                return items;
            },
            GetOptions_SubSector()
            {
                let items = [];

                var req = {
                    payload: {
                        search: "",
                        tableName: "formdata",
                        parameters: { sektor: this.model.sector.toString() },
                        column: {
                            itemText: 'text'
                        }
                    },
                    queryName: "tedarik_altsektor_key",
                    notAuthorizedRequest: true
                };
                req.success = function (result)
                {
                    result.data.listResult.forEach(function (obj)
                    {
                        items.push({ text: obj.text, value: obj.value.toString(), text_tr: obj.text_tr, text_en: obj.text_en, sektorid: obj.sektorid });
                    });
                };
                req.error = function (err)
                {
                    console.log(err);
                };

                this.$store.dispatch("GetLOV", req);

                this.items.subsector = items;
            },
            handleBlurCompanyName: function (e)
            {
                this.model.companyname = e.target.value.toLocaleUpperCase();
            },
            handleBlurEmail: function (e)
            {
                this.model.email = e.target.value.toLocaleLowerCase();
            },
            Changed_TurkeyBasedAbroad()
            {
                this.disabled.issoletrader = (this.model.turkeybasedabroad == '');
                if (this.disabled.issoletrader)
                {
                    this.model.issoletrader = '';
                    this.Changed_IsSoleTrader();
                }

                this.disabled.vknortckn = (this.model.issoletrader == '' || this.model.turkeybasedabroad == '' || this.model.turkeybasedabroad == '30');
                if (this.disabled.vknortckn)
                {
                    this.model.vknortckn = '';
                }

                if (this.model.turkeybasedabroad == '')
                {
                    this.model.gsm = '';
                    this.model.phonecode = '90';
                }

                if (this.model.turkeybasedabroad == '42')
                {
                    this.model.phonecode = '90';
                }
            },
            Changed_IsSoleTrader()
            {
                this.disabled.vknortckn = (this.model.issoletrader == '' || this.model.turkeybasedabroad == '' || this.model.turkeybasedabroad == '30');
                if (this.disabled.vknortckn)
                {
                    this.model.vknortckn = '';
                }

                if (this.model.issoletrader == '1')
                {
                    this.customtext.vknortckn = 'TCKN';
                }
                else
                {
                    this.customtext.vknortckn = 'VKN';
                }
            },
            Changed_Sector()
            {
                this.model.subsector = '';

                this.disabled.subsector = this.model.sector == '';

                if (this.model.sector.length == 0)
                {
                    this.items.subsector = [];
                }
                else
                {
                    this.GetOptions_SubSector();
                }
            },
            Changed_Country()
            {
                let iso = '';

                if (this.model.country != null)
                {
                    iso = this.model.country.iso;
                }

                this.model.province = '';
                this.model.district = '';

                this.disabled.province = iso != 'TUR';
                this.disabled.district = true;

                if (iso == 'TUR')
                {
                    this.GetOptions_Province();
                }
            },
            Changed_Province()
            {
                this.model.district = '';

                this.disabled.district = (this.model.province || '') == '';

                if ((this.model.province || '') != '')
                {
                    this.GetOptions_District();
                }
            },
            Changed_Phonecode()
            {
                this.model.gsm = '';
            },
            Remove_Sector(item)
            {
                const index = this.model.sector.indexOf(item.value);
                if (index > -1)
                {
                    this.model.sector.splice(index, 1);
                }

                this.Changed_Sector();
            },
            Remove_SubSector(item)
            {
                const index = this.model.subsector.indexOf(item.value);
                if (index > -1)
                {
                    this.model.subsector.splice(index, 1);
                }
            },
            Filter_SectorAndSubSector(item, queryText, itemText)
            {
                if (Utilities.GetCurrentLanguage() == 'en')
                {
                    return item.text_en.toLocaleLowerCase('en').indexOf(queryText.toLocaleLowerCase('en')) > -1
                }
                else
                {
                    return item.text_tr.toLocaleLowerCase('tr').indexOf(queryText.toLocaleLowerCase('tr')) > -1;
                }
            },
            Filter_Phonecode(item, queryText, itemText)
            {
                return item.value.indexOf(queryText) > -1 || item.ulke_en.toLocaleLowerCase('en').indexOf(queryText.toLocaleLowerCase('en')) > -1 || item.ulke_tr.toLocaleLowerCase('tr').indexOf(queryText.toLocaleLowerCase('tr')) > -1;
            },
            Filter_Country(item, queryText, itemText)
            {
                return item.value.indexOf(queryText) > -1 || item.text.toLocaleLowerCase('en').indexOf(queryText.toLocaleLowerCase('en')) > -1 || item.ulke_tr.toLocaleLowerCase('tr').indexOf(queryText.toLocaleLowerCase('tr')) > -1;
            },
            Filter_Province(item, queryText, itemText)
            {
                return item.value.indexOf(queryText) > -1 || item.text.toLocaleLowerCase('tr').indexOf(queryText.toLocaleLowerCase('tr')) > -1;
            },
            Filter_District(item, queryText, itemText)
            {
                return item.value.indexOf(queryText) > -1 || item.text.toLocaleLowerCase('tr').indexOf(queryText.toLocaleLowerCase('tr')) > -1;
            },
        },
        mounted: function ()
        {
            this.InitializeForm();
            this.ReloadCaptcha(true);

            window.scrollTo(0, 0);
        },
    }
</script>